import React from 'react'
import './Footer.css'

import footer_logo from '../Assets/logo.webp'
import instagram_icon from '../Assets/instagram_icon.png'
// import pintrest_icon from '../Assets/pintester_icon.png'
import whatsapp_icon from '../Assets/whatsapp_icon.png'
import location_icon from '../Assets/location.png'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-logo">
        <img src={footer_logo} alt="" />
        <p>FASHION SPICE</p>
      </div>
      <ul className="footer-links">
        <li>Outlet</li>
        <li>Products</li>
        {/* <li>Offices</li> */}
        <li>About</li>
        <li>Contact</li>
      </ul>
      <div className="footer-social-icons">
        <div className="footer-icons-container">
            <img src={instagram_icon} alt="" />
        </div>
        {/* <div className="footer-icons-container">  */}
            {/* <img src={pintrest_icon} alt="" /> */}
        {/* </div> */}
        <div className="footer-icons-container">
  <button onClick={() => window.open('https://wa.me/919833036719', '_blank', 'noopener,noreferrer')}>
    <img src={whatsapp_icon} alt="wApps_Icon" />
  </button>
</div>
<div className="footer-icons-container">
  <button onClick={() => window.open('https://maps.app.goo.gl/cvAjDtRWc3178cWS8', '_blank', 'noopener,noreferrer')}>
    <img src={location_icon} alt="LocationIcon" />
  </button>
</div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>Copyright @ 2024 - All Right Reserved.</p>
      </div>
    </div>
  )
}

export default Footer
